<template>
  <div class="page-header">
    <slot></slot>
  </div>
</template>

<style lang="scss">
.page-header {
  display: flex;
  width: 100%;
  height: 200px;
  position: relative;
  justify-content: center;
  align-items: center;

  &::after {
    content: "";
    background: rgba(51, 51, 51, 0.5);
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 52;
  }

  img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: 50;
  }
  h1 {
    position: absolute;
    z-index: 80;
    color: var(--white);
    font-size: 1.8rem;
    text-align: center;
    margin: 40px 0 10px 0;
    font-family: "Oswald", sans-serif;

    @media (min-width: 600px) {
      font-size: 2rem;
    }

    @media (min-width: 1024px) {
      margin: 40px 0;
    }
  }
}
</style>
