<template>
  <PageHeader>
    <h1>Portfolio</h1>
    <img src="@/assets/img/hero.jpg" />
  </PageHeader>
  <div class="portfolio">
    <PortfolioImages />

    <!--
    <div class="badge-container">
      <div v-for="badge in markers" :key="badge.name">
        <Badge :name="badge.name" :image="badge.image" />
      </div>
    </div>
    <Map v-if="markers.length > 0" :markers="markers" />
    -->
  </div>
</template>

<script>
import { defineComponent } from "vue";
import PageHeader from "@/components/global/PageHeader.vue";

export default defineComponent({
  components: {
    PageHeader,
  },
  data() {
    return {
      markers: [],
    };
  },
  mounted() {
    fetch(
      "https://wordpress-vue.herokuapp.com/index.php/wp-json/markers/v1/post"
    )
      .then((r) => r.json())
      .then((res) => (this.markers = res.map((x) => x.acf)));
  },
});
</script>
